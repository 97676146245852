import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'
import useProduct from '@/views/product/useProduct'
import productFilterModal from '@/modal/productFilterModal'

export default function useProductOfficeList() {
  const {
    productMenuType,
    productMenuTypeVI,
    productListMenuType,
    //
    deleteProduct,
  } = useProduct()

  const filter = productFilterModal()
  const products = ref([])
  const currentProduct = ref({})
  const created = ref(false)

  const userData = computed(() => store.state.app.userData)
  const districts = computed(() => store.state.app.districts)
  const wardsByDistricts = computed(() => store.state.app.wardsByDistricts)
  const streetsByDistricts = computed(() => store.state.app.streetsByDistricts)
  const users = computed(() => store.state.app.users)
  const routeName = computed(() => store.state.app.currentRouteName)

  const showImage160 = ref([])

  // Table products
  const tableColumns = ref([
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'minw-80 sticky-column-left',
      thClass: 'sticky-column-left',
    },
    {
      label: 'Ảnh',
      key: 'image',
    },
    {
      label: 'Tên',
      key: 'name',
      thClass: 'minw-250',
    },
    {
      label: 'Mô tả diện tích',
      key: 'rental_area_description',
    },
    {
      label: 'Kết cấu',
      key: 'ket-cau',
      thClass: 'minw-110',
      formatter: (value, key, item) => renderProductOfficeStructure(item),
    },
    {
      label: 'Giá trọn gói',
      key: 'price',
    },
    {
      label: 'Diện tích thuê',
      key: 'floor_area',
    },
    {
      label: 'Thuê nguyên căn',
      key: 'is_rent_all_apartment',
      formatter: (value, key, item) => (item.is_rent_all_apartment ? 'Nguyên căn' : 'Không'),
    },
    {
      label: 'Ngày đăng',
      key: 'ngay-dang',
    },
    {
      label: 'Hành động',
      key: 'actions',
      tdClass: 'sticky-column-right text-right',
      thClass: 'sticky-column-right',
    },
  ])
  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const totalProducts = ref(0)

  const buildQuery = customFilter => http.buildQuery(customFilter || Object.assign(filter, {
    page: currentPage.value - 1,
    perpage: perPage.value,
    user_id: productListMenuType.value === 'list' ? filter.user_id : userData.value.id,
    type_product: productMenuType.value,
    currency: productMenuType.value === 'hired' ? 'usd' : 'billion',
  })) || ''

  const fetchExpireProducts = customFilter => {
    const queryString = buildQuery(customFilter)
    return http.handle(store.dispatch('product/fetchExpireProducts', queryString), res => {
      products.value = res.data?.result || []
      totalProducts.value = res.data?.total
    })
  }

  const clearFilter = initFilter => {
    Object.assign(filter, productFilterModal(), initFilter)
  }

  watch([currentPage, perPage], () => {
    fetchExpireProducts()
  })
  watch(() => filter.province_id, n => {
    if (!n) return
    store.dispatch('app/fetchDistricts', { province_id: n }).then(res => {
      const arr = res.data?.data || []
      store.dispatch('app/fetchStreetsByDistricts', http.buildQuery({ district_ids: arr.map(item => item.id).join(',') }))
    })
    if (created.value) {
      filter.district_id_in = []
      filter.street_name_in = []
    }
  })
  watch(() => filter.district_id_in, n => {
    if (!n || !filter.province_id) return
    store.dispatch('app/fetchWardsByDistricts', http.buildQuery({ district_ids: n }))
    if (created.value) {
      filter.ward_id_in = []
    }
  })

  return {
    showImage160,
    filter,
    districts,
    wardsByDistricts,
    streetsByDistricts,
    users,
    productMenuType,
    productMenuTypeVI,
    totalProducts,
    products,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    userData,
    routeName,
    currentProduct,
    productListMenuType,
    created,
    clearFilter,
    buildQuery,
    // api
    fetchExpireProducts,
    deleteProduct,
  }
}
